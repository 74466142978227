@charset "utf-8";
@import 'settings';
@import '~foundation-sites/scss/foundation';
@include foundation-everything(true, true);
@import '~motion-ui/motion-ui';
@include motion-ui-transitions;
@include motion-ui-animations;
//@import "~animate.css";
@import '~simplelightbox';
@import '~file-icon-vectors/dist/file-icon-classic.min.css';
@import '~tiny-slider/src/tiny-slider.scss';
@import '~intl-tel-input/build/css/intlTelInput.css';
@import "custom";
