// Settings
.iti { width: 100%; margin: 0 0 rem-calc(15) 0; }

$white: #fff;
$grey: #F6F6F6;
$gray-light: #474e54;
$gray-white: #EDEDEA;
$small-title: #59618f;
$text-color: #6d6d6d;
$dark: #343434;
$dark-gray: #939384;

$app-colors: join($foundation-palette, (
        white: $white,
        grey: $grey,
        gray-light: $gray-light,
        gray-white: $gray-white,
        small-title: $small-title,
        text-color: $text-color,
        dark: $dark,
        dark-gray: $dark-gray,
));
@each $key, $val in $app-colors {
  .text-color-#{"" + $key} {
    color: $val;
  }
  .background-color-#{"" + $key} {
    background-color: $val;
  }
}
@keyframes grow {
  100% {
    width: 100%;
  }
}

body {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  color: $text-color;
  background-color: #FFF;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  color: $dark;
  display: block;
}
.h2-strong {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  color: #343434;
  display: block;
  font-size: 2.1875rem !important;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#main {
  padding: 0 !important;
  i, i a, a {
    color: darken($primary-color, 20%);
  }
}
.main_all {
  margin: rem-calc(30) 0 !important;
}
.main_contact {margin: 0 !important;}

header {
  margin: 0 !important;
  padding: 0 !important;
  .head-logo {
    height: rem-calc(80);
    padding: rem-calc(5) 0;
  }
  .search-button-header {
    color: $primary-color !important;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: inline-block;
  }
  a {
    color: $black;
  }
}
footer {
  margin: 0 !important;
  color: $white;
  background-color: #333B41;
  font-size: rem-calc(15);
  h3 {
    font-size: rem-calc(20);
    color: $white !important;
    text-transform: uppercase;
  }
  .copyright {
    background-color: $gray-light;
    font-size: rem-calc(12) !important;
    padding: rem-calc(30) 0;
    a {
      color: $white;
    }
  }
  .footer-menu {
    margin: 0;
    padding: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    li {
      list-style: none;
      a {
        padding: 0 0 rem-calc(8) 0;
        color: $white;
        background-image: linear-gradient($primary-color, $primary-color);
        background-size: 0 3px, auto;
        background-repeat: no-repeat;
        background-position: center bottom;
        transition: all .2s ease-out;
        &:hover {
          transition: all .5s ease;
          background-size: 100% 3px, auto;
          color: $primary-color !important;
        }
      }
      margin: 0 0 rem-calc(12) 0 !important;
      padding: 0 !important;
      @include breakpoint(small only) {
        list-style: none;
      }
    }
    a {
      padding: 0 0 rem-calc(8) 0;
      color: $white;
      &:hover {
        transition: all .5s ease;
        color: $primary-color !important;
      }
    }
    i {
      font-size: rem-calc(14);
    }
  }
}
mark {
  background: $primary-color;
  color: $white;
}

// Search box
.read-more-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 30px;
  z-index: 1000000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
}
.search-box {
  margin: 0 !important;
  padding: rem-calc(30) rem-calc(10) 0 rem-calc(10);
  .search-input{
    input {
      box-shadow: none !important;
      border-top: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
      border-right: 1px solid $white;
      border-bottom: 1px solid $primary-color;
      border-radius: $global-radius 0 0 $global-radius !important;
      background-color: $white;
      height: rem-calc(38);
      background-image: none !important;
      color: $black;
    }
  }
  .search-submit{
    padding: rem-calc(9) rem-calc(9) rem-calc(9) rem-calc(9);
    height: rem-calc(38);
    text-align: center;
    background-color: $primary-color;
    border-radius: 0 $global-radius $global-radius 0 !important;
    cursor: pointer;
    i {
      font-size: rem-calc(22);
      font-weight: bold;
      color: #EFF0F0;
    }
  }
}

// Form
[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'],
[type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  height: auto;
}
select {
  background-image: none;
}
input[type="submit"] {
  @include button(false, $primary-color, auto, auto, solid);
  margin-bottom: 0;
}
input[required], select[required], textarea[required] {
  background-image: url("../images/asteris.jpg");
  background-position: right top 45%;
  background-repeat: no-repeat;
}
input[type="checkbox"] {
  background-image: none;
}
[type="checkbox"] + label, [type=radio] + label {
  display: inline-flex;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}

// Buttons
.button {
  @include button(false, darken($primary-color, 10%), auto, auto, solid);
  color: $white !important;
  padding: rem-calc(15) rem-calc(30);
  font-weight: 700;
  font-size: rem-calc(16);
  letter-spacing: .04em;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: .25s all ease;
  white-space: pre-wrap;
  border-radius: 35px;
  i {
    color: $white !important;
  }
  &:hover {
    background-color: $secondary-color;
    color: $white !important;
  }
}
.button-white {
  @include button(false, $white, auto, auto, solid);
  display: inline-block;
  color: $dark !important;
  border: 2px solid $primary-color !important;
  border-radius: 25px;
  &:hover {
    background-color: $secondary-color;
    color: $white !important;
    border: 2px solid $secondary-color !important;
    background-size: 100% 0;
    transition: .4s;
    i {
      color: $white;
    }
  }
  padding: rem-calc(15) rem-calc(30);
  font-weight: 400;
  font-size: rem-calc(16);
  letter-spacing: .04em;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  transition: .25s all ease;
  white-space: pre-wrap;
}
.button-more {
  @include button(false, $secondary-color, auto, auto, solid);
  display: inline-block;
  font-size: rem-calc(18);
  border-radius: 35px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  color: #077ba2 !important;
  &:hover{
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    background: transparent;
    color: $dark !important;
  }
}

// Help classes
time { color: #6d6d6d;
  font-style: italic;
  font-size: rem-calc(12); }
table.table-preview tr td:first-child {
  font-weight: bold;
}
.no-background {
  background: none;
}
.bold { font-weight: bold;}
.round {
  border-radius: $global-radius;
}
.checkbox, .radio {
  label {
    float: left;
    font-size: $lead-font-size;
    @include breakpoint(medium down) {
      font-size: $global-font-size;
    }
  }
}
.profile_image{
  width: rem-calc(240);
  height: rem-calc(240);
  max-width: rem-calc(240);
  max-height: rem-calc(240);
  @include border-radius(50%);
}
.ul-custom {
  list-style: none;
  .colored li::before {
    content: "\2022";
    font-size: 20px;
    color: $secondary-color;
    font-weight: bold;
    display: inline-block;
    width: rem-calc(18);
    margin: 0 0 2px 0;
    vertical-align: middle;
  }
  .check li::before {
    font-family: "Font Awesome 5 Free", serif;
    content: "\f058";
    margin-right: 13px;
    color: $secondary-color;
  }
}
.ul-icon {
  padding: 0 !important;
  justify-content: center;
  display: flex;
  flex-flow: row wrap;
  li {
    list-style: none;
    text-align: center;
    box-sizing: border-box;
    display: list-item;
    margin: rem-calc(8);
    width: rem-calc(100);
    height: rem-calc(100);
    border-radius: 15px !important;
    padding-top: rem-calc(25) !important;
    background-color: $primary-color;
    color: $white;
    i {
      color: $white;
    }
    &:hover {
      background-color: $secondary-color;
      color: $white !important;
      i {
        animation: pulse;
        animation-duration: 2s
      }
    }
  }
  a.selected {
    li {
      background-color: $secondary-color !important;
      color: $white !important;
      i {
        color: $white !important;
        animation: pulse;
        animation-duration: 2s
      }
    }
  }
}
.equal-height-cards {
  .cell {
    display: flex;
    align-items: stretch;
  }
}

// Menu
.title-bar {
  justify-content: space-between;
  font-size: rem-calc(24);
  color: $dark;
  background-color: $white;
  .title-bar-title {
    color: $dark !important;
    cursor: pointer;
  }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: $primary-color;
    box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
    content: "";
  }
}
.top-bar {
  padding: 0 !important;
  margin: 0 !important;
  a {
    padding: rem-calc(22) rem-calc(25) !important;
    font-size: rem-calc(18);
    color: $dark;
    background-color: $white;
    font-weight: 500;
    &:hover {
      color: $primary-color !important;
      background-color: $white;
      &:after{
        color: $dark !important;
      }
    }
  }
  .active {
    a {
      color: $white !important;
      &:hover {
        color: $dark;
        background-color: $primary-color;
      }
    }
  }
  .drilldown {
    margin: 0;
    padding: 0 !important;
    z-index: 99999999999999999999999;
    a {
      padding: rem-calc(22) rem-calc(25) !important;
      &:hover {
        color: $dark !important;
        background-color: $white
      }
    }
    .active {
      a {
        color: $primary-color !important;
        background-color: $white  !important;
        &:hover {
          color: $dark;
          background-color: $white !important;
        }
      }
    }
  }
  .dropdown .is-dropdown-submenu {
    //columns: 2;
    //-webkit-columns: 2;
    //-moz-columns: 2;
    a {
      padding: rem-calc(22) rem-calc(25) !important;
      color: $dark !important;
      background-color: $white;
      border-top: 0 !important;
      font-size: rem-calc(15) !important;
      text-align: left;
      white-space: nowrap;
      z-index: 99999999999999999999999;
      &:hover {
        color: $primary-color !important;
        background-color: $white !important;
      }
    }
    .active {
      a {
        color: $primary-color !important;
        background-color: $white !important;
        &:hover {
          color: $dark !important;
          background-color: $white !important;
        }
      }
    }
  }
  .is-dropdown-submenu {
    z-index: 99999999999999999999;
    margin: 0;
  }
  .is-dropdown-submenu-parent.opens-right {
    a {
      padding: rem-calc(22) rem-calc(35) rem-calc(22) rem-calc(25) !important;
      &:hover {
        color: $white !important;
        background-color: $primary-color !important;
      }
    }
  }
  .is-dropdown-submenu-parent.is-active > a:first-child {
    color: $white !important;
    background-color: $primary-color;
  }
  .drilldown .js-drilldown-back > a::before {
    border-color: transparent $grey transparent transparent;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    content: "\f107" !important;
    font-family: "Font Awesome 5 Free", serif;
    color: $dark;
    font-weight: 900;
    font-size: 15px;
    margin: -7px 14px 0 0;
    right: 8px;
    border: none;
    &:hover{
      border-color: $grey !important;
    }
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent.active > a::after {
    color: $white;
  }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    content: "\f104" !important;
    color: $dark;
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    font-size: 15px;
    margin: -7px 5px 0 0;
    right: -10px;
    border: none;
    &:hover{
      border-color: $grey !important;
    }
  }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after,
  .drilldown .is-drilldown-submenu-parent > a::after {
    content: "\f105" !important;
    color: $dark;
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    font-size: 15px;
    margin: -7px 24px 0 0;
    left: 90%;
    border: none;
    &:hover {
      border-color: $grey !important;
    }
  }
  .drilldown .js-drilldown-back > a::before {
    content: "\f104" !important;
    color: $dark;
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
    font-size: 15px;
    margin: -14px 15px 0 5px;
    left: -30px;
    border: none;
    &:hover{
      border-color: $grey !important;
    }
  }
  .drilldown .is-drilldown-submenu {
    background: transparent !important;
  }

}
.top-active > a:first-child {
  color: $white !important;
  background-color: $primary-color;
}
.sticky-container, .is-stuck {
  z-index: 10000;
  box-shadow: 0 1px 0 rgba(0,0,0, 0.2);
  margin: 0;
}
.menu_id_15 {
  a {
    font-weight: 700;
    background: linear-gradient(90deg, #01acf8 0%, #00dfa3 100%) !important;
    color: $white !important;
    &:hover {
      color: $white !important;
      transition: background-color 2s ease-out 100ms;
      background: linear-gradient(90deg, #00dfa3 0%, #01acf8 100%) !important;
    }
  }
}

// Box
.top-header-box {
  background-color: $grey;
  font-size: rem-calc(14);
  padding: rem-calc(5) 0;
  color: $text-color;
  i { margin: 0 rem-calc(10) 0 0;}
  a { color: $text-color; }
}
.card-default-box {
  h2 {
    font-size: rem-calc(22);
  }
}
.card-news-box {
  border: 1px solid $white;
  .card-section{
    padding: rem-calc(10) 0 0 0;
  }
  h2{
    margin: rem-calc(5) 0 0 0;
    color: $dark;
    a {
      color: $dark-gray;
      font-weight: bold;
    }
  }
  time {
    font-family: Georgia, Arial, san-serif, serif;
    font-size: rem-calc(16);
    font-style: normal;
    margin: 0 0 rem-calc(2) 0;
    color: $dark;
    display: block;
  }
  .card-divider {
    padding-top: rem-calc(5) !important;
    background-color: $white;
    a {
      i {
        color: $dark !important;
      }
      color: $dark;
      font-weight: 400;
      &:hover {
        i {
          color: $primary-color !important;
        }
        color: $primary-color;
      }
    }
  }
}
.card-service-box {
  &:hover {
    box-shadow: 0 3px 12px rgba(110, 123, 157, 0.5);
  }
  border-radius: 8px;
  .image-box:hover {
    img {
      transform: scale3d(1.05, 1.05, 1.05);
    }
  }
  h3 {
    font-size: rem-calc(20);
    color: $primary-color;
    a { color: $primary-color !important;
      &:hover {
        color: $secondary-color !important;;
      }
    }
  }
}
.card-product-box {
  &:hover {
    box-shadow: 0 3px 12px rgba(110, 123, 157, 0.5);
    h2.card-section {
      color: $primary-color !important;
    }
  }
  .image-box:hover {
    img {
      transform: scale3d(1.05, 1.05, 1.05);
    }
  }
  h2 {
    font-size: rem-calc(20);
    a { color: $dark-gray !important;
      &:hover {
        color: $primary-color !important;
      }
    }
    &:hover {
      color: $primary-color !important;
    }
  }
}
.card-news-short-box {
  border: 1px solid $white;
  .card-section{
    padding: 1rem 0 0 0;
  }
  h2{
    font-size: rem-calc(20);
  }
}
.card-image-middle-box {
  border: none;
  .card-divider {
    padding: 1rem 0;
    font-size: rem-calc(18);
    font-weight: 300;
  }
}
.card-icon-box {
  border: 0 !important;
  i {
    margin: 0 0 rem-calc(8) 0;
    font-size: 25px;
    padding: 10px 15px;
  }
  h2 {
    a {
      color: $small-title !important;
      &:hover { color: $primary-color; }
    }
  }
  .card-section { padding: 0; }
  .card-divider {
    padding: 0;
  }
}
.card-box-page-relation {
  .card-divider {
    background-color: $white;
  }
}
.card-short-box {
  border: 0 !important;
  margin: rem-calc(5);
  .card-image{
    position: relative;
    img {
      opacity: 1.0;
    }
    img:hover {
      opacity: 0.5;
      -webkit-transition: all 0.50s;
      transition: all 0.50s;
    }
    .title {
      display: block;
      position: absolute;
      width: 100%;
      height: 36px;
      z-index: 10;
      bottom: 0;
      left: 0;
      padding: rem-calc(10);
      background-color: #282828;
      &:hover {
        background-color: rgb(28,28,28, 0.7);
      }
      h2 {
        color: $white !important;
        margin: 0;
        padding: 0;
        font-size: rem-calc(16);
        &:hover {
          color: $primary-color !important;
          i {
            color: $white !important;
          }
        }
      }
    }
  }
}
.home-inline-title-box {
  background: linear-gradient(90deg, #01acf8 0%, #00dfa3 100%);
  // background: url(../images/homepage-bottom-bg.jpg) center center no-repeat fixed;
  background-size: cover;
  color: #fff;
  padding: rem-calc(80) 0;
  text-align: center;
  .title {
    font-size: rem-calc(36);
    font-weight: 900;
    line-height: rem-calc(40);
    text-transform: uppercase;
  }
}
.home-inline-free-quote-box {
  .title {
    font-size: rem-calc(36);
    font-weight: 900;
    line-height: rem-calc(40);
    text-transform: uppercase;
  }
  padding: rem-calc(80) 0;
  text-align: center;
  background-color: #F6F6F6;
}
.social-box {
  list-style: none;
  margin: 0;
  padding: 0;
  @include breakpoint(medium) {
    display: inline-block !important;
    justify-content: left;
  }
  @include breakpoint(small) {
    display:flex !important;
    justify-content: center;
  }
  li {
    display: inline-block;
    margin: 0 rem-calc(10) rem-calc(2) 0 !important;
  }
}

// Cell
.pageSection-cell-box {
  padding: 0 !important;
  margin: rem-calc(15) 0;
}

// Pagination
.my-pagination {
  @include pagination-container;
  text-align: center;
  .pagination{
    width: 100% !important;
    margin: rem-calc(10) auto 0 auto;
    li {
      vertical-align: top;
      margin: rem-calc(7) rem-calc(7);
      width: rem-calc(48);
      height: rem-calc(48);
      //@include border-radius(50%);
      text-align: center;
      &::before {
        content: none !important;
      }
      a {
        font-size: rem-calc(18);
        line-height: 42px;
        color: #666666;
        border: none !important;
        display: block;
        &:hover{
          color: $white;
          background-color: $secondary-color !important;
          //@include border-radius(50%);
        }
      }
      &:hover{
        background-color: $secondary-color !important;
        //@include border-radius(50%);
        a {
          color: $white !important;
        }
      }
    }
    .current{
      font-size: rem-calc(18);
      line-height: 42px;
      color: $white;
      background: $primary-color;
      @include border-radius(50%);
    }
    .pagination-next {
      font-weight: normal;
      a {
        background: #f1f1f1;
        //@include border-radius(50%);
        text-align: center;
        border: 1px solid #f1f1f1;
        color: #666666;
        width: rem-calc(48);
        height: rem-calc(48);
        &:hover{
          color: $white !important;
          i{
            color: $white !important;
          }
        }
        &::after {
          content: none !important;
        }
      }
      i {
        margin: 0;
        font-weight: 900;
        padding: 2px 0 0 2px;
        font-size: rem-calc(28);
        line-height: rem-calc(36);
        &:hover{
          color: $white !important;
        }
      }
      &:hover{
        background: none !important;
      }
    }
    .pagination-previous {
      font-weight: normal;
      a {
        background: #f1f1f1;
        //@include border-radius(50%);
        text-align: center;
        border: 1px solid #f1f1f1;
        color: #666666;
        width: rem-calc(48);
        height: rem-calc(48);
        &:hover{
          color: $white !important;
          i{
            color: $white !important;
          }
        }
        &::before {
          content: none !important;
        }
      }
      i {
        margin: 0;
        font-weight: 900;
        padding: 2px 2px 0 0;
        font-size: rem-calc(28);
        line-height: rem-calc(36);
        &:hover{
          color: $white !important;
        }
      }
      &:hover{
        background: none !important;
      }
    }
    .disabled{
      display: none;
      &::after {
        content: none !important;
      }
      &::before {
        content: none !important;
      }
    }
  }
}

// Sliders
#index-main-slider {
  padding: 0;
  margin: 0;
  .swiper-button-prev,
  .swiper-button-next {
    color: $primary-color;
    padding: rem-calc(30) !important;
    top: 48% !important;
  }
  .swiper-wrapper {
    padding-bottom: 40px !important;
  }
  .swiper-slide {
    padding: 0;
    margin: 0;
    height: 500px;
    .info-box {
      position: absolute;
      width: 360px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 90%;
      h1 {
        text-align: center;
        font-size: 250%;
        color: $white;
        font-weight: bold !important;
        text-transform: uppercase;
        text-shadow: 1px 1px 2px $white;
      }
      @include breakpoint(medium) {
        width: 520px;
        font-size: 100%;
      }
      @include breakpoint(large) {
        width: 720px;
        font-size: 100%;
      }
      .more_text {
        padding: rem-calc(30) rem-calc(50);
        color: $white;
        background: rgba(43,43,43,.4);
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    bottom: 0;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    margin: 0 rem-calc(10);
    background-color: $primary-color;
  }
  .swiper-pagination-bullet-active {
    background-color: $dark;
  }
}

// Scroll to top
#oto-back-to-top {
  cursor: pointer;
  -moz-transition:all 0.4s ease;
  -o-transition:all 0.4s ease;
  -webkit-transition:all 0.4s ease;
  transition:all 0.4s ease;
  background: scale-color($primary-color, $lightness: -20%);
  border-radius:50%;
  // border-radius: 5px 5px;
  bottom: 60px;
  right: 25px !important;
  color:transparent;
  font-size:0;
  line-height:0;
  margin-right:0 !important;
  padding: 16px 0 0 0;
  position: fixed;
  text-align: center;
  text-decoration: none;
  width: 32px;
  height: 32px;
  z-index:999;
  background:none;
  opacity:1 !important;
  box-shadow:0 0 0 4px scale-color($primary-color, $lightness: 10%);
  &:before {
    -o-transition:all 0.4s ease;
    -webkit-transition:all 0.4s ease;
    -moz-transition:all 0.4s ease;
    transition:all 0.4s ease;
    border-radius:50%;
    //border-radius: 5px 5px;
    pointer-events:none;
    position:absolute;
    width:100%;
    height:100%;
    content:'';
    -webkit-box-sizing:content-box;
    -moz-box-sizing:content-box;
    box-sizing:content-box;
    top:-2px;
    left:-2px;
    padding:2px;
    z-index:-1;
    background: scale-color($primary-color, $lightness: -20%);
    opacity:1;
  }
  &:after {
    -moz-transition:all 0.4s ease;
    -o-transition:all 0.4s ease;
    -webkit-transition:all 0.4s ease;
    transition:all 0.4s ease;
    color: #FFF;
    font-size: rem-calc(30);
    content: "\f106";
    font-family: "Font Awesome 5 Free", serif;
    font-weight: 900;
  }
  &:hover {
    background: linear-gradient(90deg, #01acf8 0%, #00dfa3 100%);
    box-shadow:0 0 0 4px scale-color($secondary-color, $lightness: 10%);
    &:before {
      transform:scale(1.3);
      opacity:0;
    }
    &:after {
      color: #FFF;
    }
  }
}

// Checkbox and radio design
input[type="checkbox"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  color: $black;
  border: 1px solid $primary-color;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 2px;
    left: 7px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: $white;
    border-color: $primary-color;
    background: $primary-color;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}
input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: top;
}

// Mix
*:-moz-placeholder {
  /* FF 4-18 */
  color: #6d6d6d;
  opacity: 1;
  font-size: rem-calc(13);
}
*:-ms-input-placeholder {
  /* IE 10+ */
  color: #6d6d6d;
  font-size: rem-calc(13);
}
*::-webkit-input-placeholder {
  color: #6d6d6d;
  font-size: rem-calc(13);
}
*::-moz-placeholder {
  /* FF 19+ */
  color: #6d6d6d;
  opacity: 1;
  font-size: rem-calc(13);
}
*::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6d6d6d;
  font-size: rem-calc(13);
}
*::placeholder {
  color: #6d6d6d;
  font-size: rem-calc(13);
}

// GDPR
.cc-nb-reject {
  background: #b30303 !important;
}
.video-container {
  button {
    background: transparent;
    color: #fff;
    font-weight: bolder;
    text-shadow: 2px 1px 2px #000;
    border: none;
    cursor: pointer;
  }
}

// External icon
.icon-Viber { color: #7360f2 !important; }
.icon-Facebook { color: #17A9FD !important; }
.icon-Whatsapp { color: #28D146 !important; }

.accordion-item.is-active {
    .accordion-title {
      background-color: $primary-color !important;
      color: $white !important;
    }
}
.thumbnail-classic-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  transition: 450ms ease-in-out;
  border-radius: 50%;
  color: #8ec942;
  border: 2px solid #d8dae2;
  font-weight: 400;
  i {
    font-size: 48px
  }
  &:hover {
    background-image: -moz-linear-gradient(0deg, #8ec942 0%, #0f9447 100%);
    background-image: -webkit-linear-gradient(0deg, #8ec942 0%, #0f9447 100%);
    background-image: -ms-linear-gradient(0deg, #8ec942 0%, #0f9447 100%);
    background-size: 200%;
    i {
      color: $white !important;
    }
  }
}
